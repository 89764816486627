import { opendata } from "./opendata";
import { tenderme } from "./tenderme";
import { farmspect } from "./farmspect";
import { valido } from "./valido";
import { digifundo } from "./digifundo";
import { prolysis } from "./prolysis";
import { farmpedia } from "./farmpedia";
import { sharespace } from "./sharespace";
import { blink } from "./blink";
import { darkmangos } from "./darkmangos";
import { shark } from "./shark";
import { smartxplorer } from "./smartxplorer";
import { bff } from "./bff";
import { unitedteachers } from "./unitedteachers";
import { azubiwohnen } from "./azubiwohnen";
import { teamhfp } from "./teamhfp";
import { teampflege } from "./teampflege";
import { teamarzneimittel } from "./teamarzneimittel";

export const projects = [
    azubiwohnen,
    teamhfp,
    teampflege,
    teamarzneimittel,
    opendata,
    tenderme,
    farmspect,
    valido,
    digifundo,
    prolysis, 
    farmpedia, 
    sharespace,
    blink, 
    darkmangos,
    shark,
    smartxplorer,
    bff, 
    unitedteachers
];
